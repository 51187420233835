.container {
  max-width: 1550px;
}


@media (min-width: 1900px) {
  .container {
    max-width: 1870px;
   }

}


body {
	margin: 0;
	font-family: "Poppins", sans-serif !important;
  src: local('Poppins'), url(./assets/fonts/Poppins-Regular.ttf) format('truetype');
	/* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', */
		/* 'Droid Sans', 'Helvetica Neue', sans-serif; */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  
}



footer {
	position: absolute;
	bottom: auto;
	width: 100%;
	height: 2.5rem;
	text-align: center;
}

#root {
	padding-bottom: 2.5rem;
	position: relative;
	min-height: 91vh;
}
/* a {
	color: white;
} */

.navbar-nav .nav-item .nav-link {
  color: white;
  transition: none;
}
hr {
  border-top: none;
}

.spinner-grow {
	color: #004877;
}

