@font-face {
	font-family: 'Poppins';
	src: local('Poppins'), url(./assets/fonts/Poppins-Regular.ttf) format('truetype');
  }
  
  @font-face {
	font-family: 'Poppins';
	font-weight: 900;
	src: local('Poppins'), url(./assets/fonts/Poppins-Regular.ttf) format('truetype');
  }
  @font-face {
	font-family: 'Poppins';
	font-weight: 700;
	src: local('Poppins'), url(./assets/fonts/Poppins-SemiBold.ttf) format('truetype');
  }
  @font-face {
	font-family: 'Poppins';
	font-weight: 600;
	src: local('Poppins'), url(./assets/fonts/Poppins-SemiBold.ttf) format('truetype');
  }

  

  
body {
	margin: 0;
	color:#000000;
	font-family: "Poppins", sans-serif;
  src: local('Poppins'), url(./assets/fonts/Poppins-Regular.ttf) format('truetype');

	/* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', */
		/* 'Droid Sans', 'Helvetica Neue', sans-serif; */
	-webkit-font-smoothing: antialiased;

	-moz-osx-font-smoothing: grayscale;
}

footer {
	position: absolute;
	bottom: auto;
	width: 100%;
	height: 2.5rem;
	text-align: center;
}

#root {
	padding-bottom: 2.5rem;
	position: relative;
	min-height: 93vh;
}
a {
	color: white;
	
}
th{
	color:#333333;
}
td{
	color:#333333;
}
