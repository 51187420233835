
@media (max-width: 600px) {

	.dataTables_wrapper.dt-bootstrap4 {
		margin-top: 4rem !important;
	}

	a.page-link.page-link {
		font-size: .7rem;

	}
	.pagination .page-item .page-link {
		font-size: .7rem !important;
	}

	#responsive-table > tbody > tr > td:last-child {
		width: 100% !important;
	}
	#responsive-table table, 
	#responsive-table thead, 
	#responsive-table tbody, 
	#responsive-table th, 
	#responsive-table td, 
	#responsive-table tr { 
		display: block; 
	}


		/* Hide table headers (but not display: none;, for accessibility) */
		#responsive-table thead tr { 
			position: absolute;
			top: -9999px;
			left: -9999px;
		}

		#responsive-table tr { border: 1px solid #ccc; }
		#responsive-table td:last-child {
			display: flex;
			width: 100%;
		}
		#responsive-table td { 
			/* Behave  like a "row" */
			border: none;
			border-bottom: 1px solid #eee; 
			position: relative;
			padding-left: 40%; 
			white-space: normal;
			text-align:left;
			/* height: 6vh; */
		}

		#responsive-table td:before { 
			/* Now like a table header */
			/* position: absolute; */
			/* Top/left values mimic padding */
			top: 6px;
			left: 6px;
			width: 45%; 
			padding-right: 10px; 
			white-space: nowrap;
			text-align:left;
			font-weight: bold;
			color: #212529;
		}

			/*
	Label the data
	*/
	/* #responsive-table td:before { content: attr(data-title); } */


    	/*
	Label the data
	*/
	#responsive-table td:nth-of-type(1):before { content: "LastName:";font-weight: bold;display: block}
	#responsive-table td:nth-of-type(2):before { content: "FirstName:";font-weight: bold; display: block}
	#responsive-table td:nth-of-type(3):before { content: "Title:"; font-weight: bold;display: block}
	#responsive-table td:nth-of-type(4):before { content: "USC ID:"; font-weight: bold;display: block}
	#responsive-table td:nth-of-type(5):before { content: "CHLA ID:"; font-weight: bold;display: block}
	#responsive-table td:nth-of-type(6):before { content: "Number:"; font-weight: bold;display: block}
	#responsive-table td:nth-of-type(7):before { content: "Contact:"; font-weight: bold;display: block}
	#responsive-table td:nth-of-type(8):before { content: "Department:"; font-weight: bold;display: block}
	#responsive-table td:nth-of-type(9):before { content: "Division:"; font-weight: bold;display: block}
	#responsive-table td:nth-of-type(10):before { content: "Type:"; font-weight: bold;display: block}
	#responsive-table td:nth-of-type(11):before { content: "Active:"; font-weight: bold;display: block}
}

th.sorting {
	font-size: 14px;
	font-weight: bold;
}

th.sorting_asc {
	font-size: 14px;
	font-weight: bold;
}

th.sorting_desc {
	font-size: 14px;
	font-weight: bold;
}


/* table.dataTable thead .sorting_desc:after {
	font-size: 10px;
	right: 16px;
	font-weight: 500;
} */


#responsive-table {
    /* width: max-content; */
	margin-bottom: 0;
	width: 100%;
    max-width: 100%;
	font-size: 14px;
}

#responsive-table > th {
	font-weight: bold;

}
#responsive-table > tbody > tr > th {
	padding: 10px !important;
	font-size: 13px;
	font-weight: 700;
}
td.displayFacultyList {
	font-size: 14px;
	font-weight: 400px;
}
#responsive-table > tbody > tr > td {
	padding: 10px !important;
	font-size: 13px;
	font-weight:600;
	color: #333333;
}
#responsive-table > tbody > tr > td:last-child {
	width: 100px;
}
#responsive-table>:not(:last-child)>:last-child>* {
	border-bottom-color: #eee;
}

#responsive-table>:not(:last-child)>:last-child>th {
	border-bottom-color: #eee;
	font-weight: bold;
}

.dataTables_info {
	font-size: 12px;
}
.dataTables_length > label {
	font-size: 13px;
	color: #333333 !important;
}
select.custom-select.custom-select-sm.form-control.form-control-sm {
	color: #333333 !important;
}
.dataTables_length > select {
	font-size: 13px;
}
.pagination .page-item.active .page-link:hover {
	background-color: #004877;
}
.pagination .page-item.active .page-link {
	background-color: #004877;

}

 .link-btn-display {
	/* height: 3rem;
    margin-left: 2%; */
    background-color: white !important;
    border-radius: 100px;
    color: #004877 !important;
    text-decoration: none;
	border: 2px solid #EEEEEE;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: .5rem;
    padding-bottom: .5rem;
/* 
	border: 1px solid rgb(9,157,219);
    background-color: rgb(9,157,219);
    color: white !important; */
    /* padding: 2px;
	border-radius: 2px; */
}

tr td:last-child {
	vertical-align: middle !important;
}

table.dataTable thead .sorting:before {
	bottom: .5em !important;
}
table.dataTable thead .sorting:after {
	bottom: .5em !important;

}
table.dataTable thead .sorting_asc:after {
	bottom: .5em !important;
}
table.dataTable thead .sorting_asc:before {
	bottom: .5em !important;
}
table.dataTable thead .sorting_desc:after {
	bottom: .5em !important;
}
table.dataTable thead .sorting_desc:before {
	bottom: .5em !important;
}
thead {
	border: 1px solid #EEEEEE !important;
    background-color: #EEEEEE !important;
}
.table-responsive {
		border: 1px solid #EEEEEE !important;
}
/* tr:nth-child(4) {
	width: 50px;
} */