@media (max-width: 600px) {


	tr {
		display: block;
	
		
		
	}
	table, tbody, td {
		display: block;
		word-wrap: break-word;
	  }
	  thead {
		display: none;
	 }
	 .droplist-edit-btn {
		 width: 50%;
		 /* margin-left: 16%; */
	 }
 
	 td.dropdownList.sp-link {
	padding-left: 50%;
	word-wrap: break-word;
}
	 
  
   
   td {
	 /* Behave  like a "row" */
	 border: none;
	 word-break: break-all;
	 border-bottom: 1px solid #eee;
	 position: static;
	 
	 padding-left: 50%;
	 display: flex;
   }
   td:before {
    /* Now like a table header */

    
    /* Top/left values mimic padding */
       top: 0;
       /* float: left; */
    left: 6px;
    width: 45%;
    padding-right: 10px;
    
	margin-right: 20px;
    }


	
    	/*
	Label the data
	*/
	.dropdownList:nth-of-type(1):before { content:  attr(data-name); font-weight: bold;display: block}
	.dropdownList:nth-of-type(2):before { content: "Created On";font-weight: bold; display: block}
	.dropdownList:nth-of-type(3):before { content: "Created By";font-weight: bold;display: block}
	.dropdownList:nth-of-type(4):before { content: "Modified On"; font-weight: bold;display: block}
	.dropdownList:nth-of-type(5):before { content: "Modified By"; font-weight: bold;display: block}
	.dropdownList:before { content: "Active"; font-weight: bold;display: block}
	
	

}


.table {
    /* width: max-content; */
	margin-bottom: 0;
	width: 100%;
    max-width: 100%;
	font-size: 14px;
}
.table > tbody > tr > th {
	padding: 4px;
}
.table > tbody > tr > td {
	padding: 4px;
	word-wrap: break-word;
}
/* tr:nth-child(4) {
	width: 50px;
} */