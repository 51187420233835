.search-container {
  /* height: 50rem; */
  height: auto;
  /* margin-top: 10%; */
  background-color: #f5f5f5;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 20px;
  /* width: max-content; */
}

.addMultiSelect {
  width: 60% !important;
}

.editMultiSelect {
  width: 100% !important;
}
.col-sm-6 > div#multiselectContainerReact {
  width: 60%;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  /* -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; */
  margin: 0;
  display: none;
  -webkit-appearance: none;
}
input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}
.chip {
  background-color: #0d9ddb !important;
}
/* .searchBox::placeholder {
  content: 'Please make a selection...' !important;
  color: #495057 !important;
  font-size: 15px !important;
} */
#search_input::placeholder {
  color: #495057 !important;
  font-size: 15px !important;
}
/* .highlightOption {
  background-color: #0d9ddb !important;

}
li.highlightOption.highlight.false.false.option:active {
  background-color: #0d9ddb !important;

} */

.text-danger {
  color: "#EA1D36";
  font-size: 12px;
  margin-left: 2%;
}
.form-control.is-invalid {
  border-color: "#EA1D36 !important";
}
.span-link-btn {
  float: right;
  margin-top: -2.5%;
}
.span-link-btn-dropdown {
  margin-top: -2%;
  float: right;
}
.pagination .page-item.disabled .page-link {
  color: #a6a6a6 !important;
}

.pagination .page-item .page-link {
  color: #333333 !important;
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E") !important;
}

.navbar.navbar-light .navbar-toggler {
  border: solid white !important;
  color: white !important;
}
/* .navbar.navbar-light .navbar-toggler:focus {
	border: 1px solid white !important;
} */

#show-all-faculty {
  background-color: #ffffff !important;
  color: #004877;
  border: #f5f5f5 !important;
  margin-left: 1%;
}
.edit_dropdown {
  height: 58% !important;
}

[data-test="table-foot"] {
  display: none;
}

table.table a {
  color: #337ab7;
}
table.table a:hover {
  color: rgb(9, 157, 219);
  text-decoration: underline;
}

.heading {
  text-align: center;
  margin-top: 5%;
}

.search-bar {
  text-align: center;
  height: 10rem;
}
.search {
  height: 3rem;
}
.search {
  outline-color: lightgray;
}

.pagination .page-item.active .page-link:hover {
  background-color: rgb(9, 157, 219);
  color: white;
}
.pagination .page-item.active .page-link {
  background-color: rgb(9, 157, 219);
  color: white !important;
}

.btn_search {
  height: 3rem;
  margin-left: 2%;
  background-color: rgb(9, 157, 219);
  border-radius: 2px;
  color: white;
  text-decoration: none;
  border: 1px solid rgb(9, 157, 219);
}

.error {
  text-align: center;
  color: #ea1d36;
  padding-bottom: 10%;
}

.setHeight {
  height: 30rem;
  width: auto;
}
.createNew {
  float: right;
  margin-top: -2rem;
}

a {
  text-decoration: none;
}

.new {
  color: #004877 !important;
  text-decoration: none !important;
  font-weight: bold;
}
.new:hover {
  text-decoration: underline !important;
}
.hr-createNew {
  text-align: center;
  margin-top: 20px;
  margin-left: 20%;
  width: 50%;
}
.hr-displayTable {
  margin-right: 10%;
  margin-left: 10%;
  /* width: 50%; */
}
.link-style {
  margin-right: 10%;
  margin-left: 10%;
}
.hr-facultyDetail {
  margin-top: 20px;
}
/* hr {
	margin-top: 20px;
    margin-bottom: 20px;
    border: 0;
    border-top: 1px solid #eee;
} */
.body {
  color: #000000;
}
.form-group {
  margin-top: 15px;
}

a.new:hover {
  color: rgb(9, 157, 219);
}
h2,
h3 {
  font-weight: 500;
  /* margin-top: 10px;
	margin-left: 20%;; */
}

.form-createNew {
  margin-left: 20%;
  font-size: 15px;
}
.form-control {
  font-size: 15px;
}
.form-control,
.custom-select {
  width: 60%;
}
.custom-select {
  height: 100%;
  border: 1px solid #ced4da;
}
.form-file-label {
  flex: 0 0 auto;
  width: 16.6666666667%;
}
.form-file {
  display: flex;
}
.edit-formfile > .form-file-label {
  width: 20%;
}

.form-control-file {
  padding-left: 10px;
}
.edit-formfile > .form-control-file {
  padding-left: 60px;
}
.imgPreview {
  text-align: center;
  margin-top: 10px;
}
.form-check-input:checked {
  background-color: #a6a6a6;
  border: 1px solid #a6a6a6;
}
.form-check {
  margin-left: 10px;
}
.dropdown .dropdown-menu .dropdown-item:active {
  background-color: #a6a6a6;
}
/* .dl-horizontal dt {
	float: left;
    width: 180px;
    overflow: hidden;
    clear: left;
    text-align: right;
    text-overflow: ellipsis;
}

.dl-horizontal dd {
    margin-left: 200px;
} */

.dl-horizontal {
  font-size: 90%;
}

dl {
  display: flex;
  flex-flow: row wrap;
  /* width: 200%; */
}
dt {
  flex-basis: 30%;
  padding: 2px 4px;
  text-align: right;
  color: #333;
  width: 100px;
  font-size: 0.9rem;
  font-weight: bold;
}
dd {
  color: #333333;
  flex-basis: 70%;
  flex-grow: 1;
  margin: 0;
  padding: 2px 8px;
}
#cart {
  flex-basis: 50%;
  flex-grow: 1;
  margin: 0;
  padding: 2px 8px;
}
.heading-facultyDetail {
  width: 70%;
  margin-top: 1rem;
}
.heading-createNew {
  margin-top: 1rem;
  margin-left: 20%;
}
.heading-displayTable {
  margin-top: 1rem;
  margin-left: 10%;
}
.typography-edit-faculty {
  margin-top: 20px;
  margin-bottom: 10px;
}
.well {
  min-height: 10px;
  padding: 8px;
  margin-bottom: 15px;
  background-color: #f5f5f5;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
}
.pull-right {
  margin-top: -30px;
  float: right;
  font-weight: 500;
}
a {
  color: #004877;
  text-decoration: none;
}

.navbar-light .navbar-brand {
  color: white;
}
.navbar-light .navbar-nav .nav-link.active {
  color: white;
}

.navbar-light .navbar-nav .nav-link {
  color: white;
}
.navbar-light .navbar-nav .nav-link:hover {
  color: white;
}
.navbar-text {
  color: white;
}
.navbar-light .navbar-text {
  color: white;
}
.navbar-light .navbar-text a {
  color: white;
}
.navbar-light .navbar-text a:hover {
  color: white;
}
.navbar-light .navbar-nav .show > .nav-link {
  color: white;
}
.navbar-light .navbar-brand:hover {
  color: white;
}

#editUser {
  margin: 0;
}
label.form-label.col-form-label.col-sm-4 {
  padding-top: 7px;
  margin-bottom: 0;
  text-align: right;
}
.col-sm-8 > .form-control {
  width: 100%;
}

.col-sm-8 > .custom-select {
  width: 100%;
}
.display-header {
  font-size: 24px;
  color: #333333;
}
.span-sp {
  padding-left: 2px;
  padding-right: 2px;
}
label.form-label.col-form-label.col-sm-4 {
  padding-left: 0px;
}
.edit-formfile {
  /* margin-left: 20%; */
  text-align: right;
  display: flex;
}
.range-min-max-values,
.input-slider {
  display: inline;
  /* position: absolute; */
}
.slider-container {
  margin-top: 10%;
  width: 100%;
}

.Clinical {
  margin-left: 30%;
}

.Administration {
  margin-left: 20%;
}

.Research,
.Teaching {
  margin-left: 27%;
}

.css-vqh9lr-MuiSlider-root {
  width: 50% !important;
  color: rgb(9, 157, 219) !important;
}

span.MuiSlider-colorPrimary.MuiSlider-root.range-margin.css-vqh9lr-MuiSlider-root {
  width: 50% !important;
  color: rgb(9, 157, 219) !important;
}

span.MuiSlider-valueLabelOpen.MuiSlider-valueLabel.css-1kz0hui-MuiSlider-valueLabel {
  background-color: rgb(9, 157, 219) !important;
}

.edit-submit {
  margin-left: 15%;
}
.img_logo {
  width: 30px;
  margin-right: 5px;
}
.navbar-light .navbar-toggler {
  color: white;
  border-color: white;
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}
button.btn-create.btn.btn-primary,
button.activity_form_label.btn.btn-primary,
button.btn.btn-primary {
  height: 3rem;
  margin-left: 2%;
  background-color: #0d9ddb !important;
  border-radius: 100px;
  color: white;
  text-decoration: none;
  /* border: 1px solid rgb(9,157,219) !important; */
}

button.btn.btn-outline-secondary {
  height: 3rem;
  border-radius: 100px;
  text-decoration: none;
  color: #6c747e !important;
  border-color: #6c747e !important;
}

button.btn.btn-danger {
  height: 3rem;
  border-radius: 100px;
  text-decoration: none;
}

/* .edit-submit > .form-control-file {
	padding-left: 20%;
} */
@media all and (device-width: 768px) and (device-height: 1024px) and (orientation: portrait) {
  .heading-facultyDetail {
    width: 100%;
  }

  .span-link-btn {
    margin-top: -5%;
  }
  .span-link-btn-dropdown {
    margin-top: -5%;
  }
  span.navbar-a-link.navbar-brand {
    margin-left: -32%;
  }
  .edit_dropdown {
    height: 3rem !important;
    margin: auto;
  }
  #table-responsive-display {
    margin: 1em 0;
    min-width: 300px;
  }
  dt {
    padding: 0 !important;
  }

  #table-responsive-display tr {
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
  }
  #table-responsive-display th {
    display: none;
  }
  #table-responsive-display td {
    display: block;
  }
  #table-responsive-display td:first-child {
    padding-top: 0.5em;
  }
  #table-responsive-display td:last-child {
    padding-bottom: 0.5em;
  }
  #table-responsive-display td:before {
    content: attr(data-title);
    font-weight: bold;
    /* width: 10rem; */
    display: block;
    width: 100%;
  }
  #table-responsive-display th,
  #table-responsive-display td {
    text-align: left;
  }
  #table-responsive-display th,
  #table-responsive-display td {
    margin: 0.5em 1em;
  }
}

@media all and (device-width: 1024px) and (device-height: 768px) and (orientation: landscape) {
  .heading-facultyDetail {
    width: 100%;
  }

  .span-link-btn {
    margin-top: -5%;
  }
  .span-link-btn-dropdown {
    margin-top: -5%;
  }
  span.navbar-a-link.navbar-brand {
    margin-left: -32%;
  }
  .edit_dropdown {
    height: 3rem !important;
    margin: auto;
  }
  #table-responsive-display {
    margin: 1em 0;
    min-width: 300px;
  }
  dt {
    padding: 0 !important;
  }

  #table-responsive-display tr {
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
  }
  #table-responsive-display th {
    display: none;
  }
  #table-responsive-display td {
    display: block;
  }
  #table-responsive-display td:first-child {
    padding-top: 0.5em;
  }
  #table-responsive-display td:last-child {
    padding-bottom: 0.5em;
  }
  #table-responsive-display td:before {
    content: attr(data-title);
    font-weight: bold;
    /* width: 10rem; */
    display: block;
    width: 100%;
  }
  #table-responsive-display th,
  #table-responsive-display td {
    text-align: left;
  }
  #table-responsive-display th,
  #table-responsive-display td {
    margin: 0.5em 1em;
  }
}

@media all and (device-width: 1024px) and (device-height: 1366px) {
  .heading-facultyDetail {
    width: 100%;
  }

  .span-link-btn {
    margin-top: -5%;
  }
  .span-link-btn-dropdown {
    margin-top: -3%;
  }
  #table-responsive-display {
    margin: 1em 0;
    min-width: 300px;
  }

  #table-responsive-display tr {
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
  }
  #table-responsive-display th {
    display: none;
  }
  #table-responsive-display td {
    display: block;
  }
  #table-responsive-display td:first-child {
    padding-top: 0.5em;
  }
  #table-responsive-display td:last-child {
    padding-bottom: 0.5em;
  }
  #table-responsive-display td:before {
    content: attr(data-title);
    font-weight: bold;
    /* width: 10rem; */
    display: block;
    width: 100%;
  }
  #table-responsive-display th,
  #table-responsive-display td {
    text-align: left;
  }
  #table-responsive-display th,
  #table-responsive-display td {
    margin: 0.5em 1em;
  }
}

@media (max-width: 600px) {
  button.edit_dropdown.btn.btn-primary {
    height: 3rem !important;
    margin: auto;
  }
  .heading-facultyDetail {
    width: 100%;
  }

  .span-link-btn {
    float: none;
    margin-top: none;
  }
  .span-link-btn-dropdown {
    margin-top: none;
    float: none;
  }

  .error {
    margin-top: 4rem;
  }

  .beat-loader {
    margin-top: 4rem !important;
  }

  .slider-container {
    margin-top: 20%;
  }

  td.displayFacultyList:last-child {
    display: flex;
  }
  /* a.link-btn-display:last-child {
		margin-left: -20%;
	} */

  dt {
    padding: 0 !important;
  }
  .img_logo {
    margin-left: -40px;
  }
  #table-responsive-display {
    margin: 1em 0;
    min-width: 300px;
  }
  .navbar-light .navbar-toggler {
    color: white;
    border-color: white;
    top: 20%;
  }

  .navbar-light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E") !important;
  }

  .navbar-light .navbar-nav .nav-link {
    color: white !important;
  }
  .navbar.navbar-light .navbar-toggler {
    color: white !important;
    border-width: 1px !important;
    margin-left: 75%;
  }

  #table-responsive-display tr {
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
  }
  #table-responsive-display th {
    display: none;
  }
  #table-responsive-display td {
    display: block;
  }
  #table-responsive-display td:first-child {
    padding-top: 0.5em;
  }
  #table-responsive-display td:last-child {
    padding-bottom: 0.5em;
  }
  #table-responsive-display td:before {
    content: attr(data-title);
    font-weight: bold;
    /* width: 10rem; */
    display: block;
    width: 100%;
  }

  @media (min-width: 480px) {
    #table-responsive-display td:before {
      display: none;
    }
  }

  #table-responsive-display th,
  #table-responsive-display td {
    text-align: left;
  }
  @media (min-width: 480px) {
    #table-responsive-display th,
    #table-responsive-display td {
      display: table-cell;
      padding: 0.25em 0.5em;
    }
    #table-responsive-display th:first-child,
    #table-responsive-display td:first-child {
      padding-left: 0;
    }
    #table-responsive-display th:last-child,
    #table-responsive-display td:last-child {
      padding-right: 0;
    }
  }

  #table-responsive-display th,
  #table-responsive-display td {
    margin: 0.5em 1em;
  }
  @media (min-width: 480px) {
    #table-responsive-display th,
    #table-responsive-display td {
      padding: 1em !important;
    }
  }

  label.form-label.col-form-label.col-sm-4 {
    text-align: left;
    padding-left: 15px;
  }
  .edit-formfile {
    text-align: left;
  }
  .edit-formfile > .form-control-file {
    padding-left: 0px;
  }
  .form-file {
    display: block;
  }

  .col-6 {
    max-width: 100%;
  }

  .edit-submit {
    margin-left: 0px;
  }
  .display-header {
    font-size: 1rem;
    font-weight: 600;
    color: #333333;
  }
  .col-lg-8.col-md-7 {
    margin-top: 1rem;
  }
  .imageUpload {
    height: 200px;
    width: 200px;
  }
  .createNew {
    margin-top: 0px;
  }
  .btn_search {
    margin-top: none;
  }
  .nav-item {
    margin-left: 0px;
  }
  .navbar-text {
    margin-left: 2rem;
  }
  a.btn.nav-btn-auth {
    margin-left: 1.2rem;
  }

  .form-control,
  .custom-select {
    width: 100%;
  }
  .form-createNew {
    margin-left: 0%;
  }
  .hr-createNew {
    margin-left: 0%;
    width: 100%;
  }
  .heading-createNew {
    margin-left: 0%;
  }
  .form-control-file {
    padding-left: 0px;
  }
  h2 {
    font-size: 1.3rem;
  }
  .btn_search {
    margin-top: 10px;
  }
  .Clinical {
    margin-left: 25%;
  }
  .Administration {
    margin-left: 6.5%;
  }
  .Research,
  .Teaching {
    margin-left: 20%;
  }
}
