@media (max-width: 800px) {
  .navbar .dropdown-menu {
    position: relative !important; 
  }

  div#responsive-navbar-nav {
    padding-left: 20%;
    text-align: right;
  }
  .dropdown-menu {
    position: relative;
  }
  .dropdown-menu.show {
    position: relative;
  }
  div >  .dropdown-menu.show {
    position: relative;
  }
  .navbar-text {
    margin-left: 14%;
  }
  a.btn.nav-btn-auth {
    margin-left: 14%;
  }
}

#logout, #login {
  text-transform: none;
  box-shadow: none;
  display: inline-block;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0px;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: .25rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.navbar {
  background-color: #004877;
  border-bottom: none;
  font-weight: 400;
  box-shadow: none;
}

.navbar .dropdown-menu a {
  padding: 0.25rem 1.5rem;
  font-weight: 400;
}

/* .nav-link:focus {
    color: white;
  }
  .nav-link .active {
    color: white;
  } */

.navbar.navbar-light .navbar-nav .nav-item .nav-link {
  color: white;
}
.navbar.navbar-light .navbar-nav .nav-item .nav-link:hover {
  color: white;
}
a.navbar-light.nav-btn-auth {
  color: white;
}
a.navbar-light.nav-btn-auth:hover {
  color: white;
}

  .dropdown-item:hover {
    background-color: #EEEEEE;

}
.dropdown-item:active {
  background-color: #A6A6A6 ;
  }

/* .nav-link,.navbar-brand, .nav-btn-auth, .navbar-text {
    color:#33aaee;
    color: white;
  } */
/* .nav-link:hover{
    color: white
  } */
/* .nav-btn-auth:hover {
    color: white;
  } */
/* .navbar-text:hover {
    color: white;
  } */
a.nav-btn-auth {
  margin-left: 3%;
  color: white;
}
.container-fluid {
  margin-left: 10%;
  margin-right: 10%;
}

.nav-item {
  margin-left: 2rem;
}
